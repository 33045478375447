import formatLocationString from '@/lib/formatLocationString';
import getTermsOfUseUrl from '@/lib/getTermsOfUseUrl';

/**
 * @ngdoc controller
 * @name generalMeetingHomeModule.BulkUploadController
 * @description This controller is used for bulk upload module. There is a
 *              functionality to upload bulk records in few of the screens.
 *              BO file upload was also part of this controller. But for change in
 *              requirement, that code was removed. If needed, please go to commit history
 *              and fetch the code back.
 */
('use strict');
angular.module('generalMeetingHomeModule').controller('BulkUploadController', [
  '$scope',
  '$sessionStorage',
  '$stateParams',
  '$log',
  'fileUploadSvc',
  'fileDownloadSvc',
  'cbpDownloadBlob',
  '$filter',
  '$timeout',
  '$http',
  'userGroupService',
  'voteInstructionService',
  'intermediaryService',
  'ModelWindowService',
  function (
    $scope,
    $sessionStorage,
    $stateParams,
    $log,
    fileUploadSvc,
    fileDownloadSvc,
    cbpDownloadBlob,
    $filter,
    $timeout,
    $http,
    userGroupService,
    voteInstructionService,
    intermediaryService,
    ModelWindowService,
  ) {
    $scope.startProgressBar = false;
    $scope.startDuplicateUpload = false;
    $scope.gmId = $stateParams.gmId;
    $scope.intermediaryId = $sessionStorage.groupId;
    $scope.userId = $sessionStorage.userId;
    $scope.issuerId = $stateParams.issuerId;
    $scope.type = $stateParams.type;
    $scope.issuerName = $stateParams.issuerName;
    $scope.gmName = $stateParams.gmName;
    $scope.gmDate = $stateParams.gmDate;
    $scope.bulkUploadLoading = false;
    $scope.fileDownloading = false;
    $scope.inviteSharePlan = 'bulkUpload';
    $scope.eventType = 'Footer Term of Use';
    $scope.shareholderId = 0;
    $scope.sendRcToShareholder = 'N';
    $scope.defaultProxyForUpload = 'N';
    $scope.checkDuplicateVI = 'Y';
    $scope.checkDuplicateAR = 'Y';
    $scope.jobExecutionId = 0;
    $scope.istelenetPolicyTextPresent = false;
    $scope.languages = [
      { language: 'Dutch', langCode: 'NL' },
      { language: 'English', langCode: 'EN' },
      { language: 'French', langCode: 'FR' },
      { language: 'German', langCode: 'DE' },
      { language: 'Spanish', langCode: 'ES' },
    ];

    $scope.selectedLanguage = $scope.languages[0];
    //Below value is hard coded to GM because VI and AR functionalities will be there only for GM users.
    //Using productType array from session storage was causing issue in case of integrated users, so it is relpaced with below value.
    $scope.productTypeToU = 'GM';

    $scope.voteCollectorList = [];

    //to load the shareclasses
    voteInstructionService.getShareClassList(
        $scope.gmId,
        $scope.shareholderId,
        $sessionStorage.workstationData.selectedLang,
        $sessionStorage.usertype
      )
      .query()
      .$promise.then(
        function (data) {
          if (data.length > 1) {
            $scope.moreThanOneSC = true;
            $scope.shareClassList = data;
            $scope.selectedSecurityClass = $scope.shareClassList[0];
            $scope.voteCollectorList = [];
            angular.forEach(
              $scope.selectedSecurityClass.vcShareclassAssocVO,
              function (key) {
                key.nameOfVC =
                  key.title +
                  ' ' +
                  key.firstName +
                  ' ' +
                  key.lastName +
                  ' ' +
                  $filter('translate')('vi_shareholder_disclaimer2') +
                  ' ' +
                  key.vcName;
                $scope.voteCollectorList.push(key);
                if ($scope.voteCollectorList.length === 1) {
                  $scope.selectedVC = $scope.voteCollectorList[0];
                }
              }
            );
          } else if (data.length === 1) {
            $scope.moreThanOneSC = false;
            $scope.shareClassList = data;
            $scope.selectedSecurityClass = $scope.shareClassList[0];
            $scope.voteCollectorList = [];
            angular.forEach(
              $scope.selectedSecurityClass.vcShareclassAssocVO,
              function (key) {
                key.nameOfVC =
                  key.title +
                  ' ' +
                  key.firstName +
                  ' ' +
                  key.lastName +
                  ' ' +
                  $filter('translate')('vi_shareholder_disclaimer2') +
                  ' ' +
                  key.vcName;
                $scope.voteCollectorList.push(key);
                if ($scope.voteCollectorList.length === 1) {
                  $scope.selectedVC = $scope.voteCollectorList[0];
                }
              }
            );
          } else {
            $scope.noShareClass = true;
          }
        },
        function (error) {
          $log.debug(error);
        }
      );

    intermediaryService.getPoaList().query()
      .$promise.then(
        function (data) {
          $scope.poaList = data.list;
          if ($scope.poaList.length === 1) {
            $scope.selectedIntermediary = $scope.poaList[0];
          }
      },
      function (error) {
        $log.debug(error);
    });

    //to proceed file uploading when there is duplicate entries.
    $scope.proceedUploadAr = function (val) {
      if (val === 'C') {
        document.getElementById('arBulkUploadForm').reset();
      }
      $scope.checkDuplicateAR = val;
      $scope.startBulkFileUploadAr();
    };

    $scope.proceedUploadVi = function (val) {
      if (val === 'C') {
        document.getElementById('viBulkUploadForm').reset();
      }
      $scope.checkDuplicateVI = val;
      $scope.startBulkFileUploadVi();
    };

    //to close the upload functionality.
    $scope.closeUploadFunc = function () {
      $scope.bulkFile = null;
      $scope.checkDuplicateVI = 'Y';
      $scope.checkDuplicateAR = 'Y';
      document.getElementById('bulkUpload').reset();
    };
    //on change of votecollector in dropdown
    $scope.onVCChange = function (selectedVC) {
      $scope.selectedVC = selectedVC;
    };
    //on security class change in dropdown
    $scope.onSecurityClassChange = function (selectedSecurityClass) {
      $scope.selectedSecurityClass = selectedSecurityClass;
      $scope.voteCollectorList = [];
      angular.forEach(
        $scope.selectedSecurityClass.vcShareclassAssocVO,
        function (key) {
          key.nameOfVC =
            key.title +
            ' ' +
            key.firstName +
            ' ' +
            key.lastName +
            ' ' +
            $filter('translate')('vi_shareholder_disclaimer2') +
            ' ' +
            key.vcName;
          $scope.voteCollectorList.push(key);
          if ($scope.voteCollectorList.length === 1) {
            $scope.selectedVC = $scope.voteCollectorList[0];
          }
        }
      );
    };
    //on change of language in dropdown
    $scope.onLanguageChange = function (selectedLanguage) {
      $scope.selectedLanguage = selectedLanguage;
    };

    $scope.termsOfUseUrl = getTermsOfUseUrl(
      'GM',
      $sessionStorage.workstationData.selectedLang
    );

    // Below is the function used to fetch issuer logo
    userGroupService.getIssuerGroupLogo($scope.issuerId).then(
      function (response) {
        $scope.issuerGroupDetails = response.data;
      },
      function (error) {
        $log.debug(error);
      }
    );

    /* download registration form starts here */
    $scope.downloadTemplate = function (type) {
      let votingMethod = 'VI';
      if (type === 'attend') {
        votingMethod = 'AR';
      }
      const downloadUrl =
        `/v2/bulk-upload-templates?votingMethod=${votingMethod}`;

      $scope.fileDownloading = true;
      fileDownloadSvc.downloadFile(downloadUrl).then(
        function (response) {
          cbpDownloadBlob(response.file, response.fileName);
          $scope.fileDownloading = false;
        },
        function (error) {
          $scope.fileDownloading = false;
        }
      );
    };

    /* download registration ends starts here */
    //to reset file values
    $scope.resetFileValues = function () {
      document.getElementById('bulkUpload').reset();
    };

    $scope.canSubmitViUpload = function () {
      return !$scope.bulkUploadLoading              // not already busy
         && !!$scope.selectedVC                     // we have vote collectors
         && !!$scope.bulkFile                       // we have a file
         && !!$scope.selectedIntermediary;          // we have an intermediary
    };

    $scope.canSubmitArUpload = function () {
      return !$scope.bulkUploadLoading             // not already busy
        && !!$scope.bulkFile                         // we have a file
        && !!$scope.selectedIntermediary;            // we have an intermediary
    };

    /**
     * Call the upload directive to upload file and FormData
     */
    $scope.bulkUploadProcess = function (file, documentFormData) {
      $scope.bulkUploadLoading = true;
      fileUploadSvc.uploadFileToUrl(file, $scope.uploadUrl, documentFormData)
        .then(
          function (data) {
            $scope.bulkUploadLoading = false;
            if (data.status === 200) {
              let fileNameStr = $scope.bulkFile.name;
              let fileNameWithoutExt = fileNameStr.replaceAll('.', '&middot');
              $scope.serviceurl = `v1/jobs/${data.data.jobExecutionId}/${fileNameWithoutExt}`;

              if (
                ($scope.checkDuplicateAR === 'C' && $scope.type === 'attend') ||
                ($scope.checkDuplicateVI === 'C' && $scope.type === 'vote')
              ) {
                $scope.startProgressBar = false;
                $scope.bulkFile = null;
                $scope.serviceurl = '';
                $scope.checkDuplicateAR = 'Y';
                $scope.checkDuplicateVI = 'Y';
                $scope.cancelUploadSuccess = data.data.successMessage;
                $('#successMsg').modal('show');
                $timeout(function () {
                  $('#successMsg').modal('hide');
                  $('.modal-backdrop').remove();
                  $scope.cancelUploadSuccess = '';
                  document.getElementById('goback').click();
                }, 4000);
              } else if (
                ($scope.checkDuplicateAR === 'N' && $scope.type === 'attend') ||
                ($scope.checkDuplicateVI === 'N' && $scope.type === 'vote')
              ) {
                $scope.startProgressBar = false;
                //for loading the second progress bar while saving the duplicate records and displaying the upload status.
                $scope.startDuplicateUpload = true;
                $scope.jobExecutionId = data.data.jobExecutionId;
                $scope.checkDuplicateAR = 'Y';
                $scope.checkDuplicateVI = 'Y';
              } else {
                $scope.startProgressBar = true;
                $scope.jobExecutionId = data.data.jobExecutionId;
                $scope.checkDuplicateAR = 'Y';
                $scope.checkDuplicateVI = 'Y';
              }
            } else {
              $scope.errorHere = true;
              $scope.errorMsg = data.data.errorMessage;
              if (!data.data.errorMessage) {
                $scope.errorMsg = data.data.errors[0].errorMessage;
              }
            }
          },
          function (error) {
            $scope.bulkUploadLoading = false;
            if (
              error.status !== 422 &&
              error.data.attendanceRequestLimtVO !== null &&
              error.data.attendanceRequestLimtVO.limitExceeded
            ) {
              $scope.modalHeader = $filter('translate')(
                'label_confirmationMessage'
              );
              $scope.modalLeftButton = $filter('translate')('label_cancel');
              $scope.modalRightButton = $filter('translate')('label_proceed');
              $scope.modalBody = error.data.attendanceRequestLimtVO.intermediaryLimit;
              ModelWindowService.showModelWindow('requestNotAllowed');
            } else {
              $log.debug(error);
              $scope.bulkUploadLoading = false;
              $scope.errorHere = true;
              $scope.errorMsg = error.data.errorMessage === ''
                  ? error.data.errors[0].errorMessage
                  : error.data.errorMessage;
              $timeout(function () {
                $scope.errorHere = false;
              }, 3000);
            }
          }
        );
    };

    $scope.startBulkFileUploadVi = function () {
      $scope.errorMsg = '';
      let documentFormData = new FormData();
      documentFormData.append('langCode', $stateParams.langCode);
      documentFormData.append('checkDuplicateVI', $scope.checkDuplicateVI);
      documentFormData.append('votecollectorId', $scope.selectedVC.vcID);
      documentFormData.append('vcUserType', $scope.selectedVC.userVcType);
      documentFormData.append('shareclassId', $scope.selectedSecurityClass.shareclassId);
      documentFormData.append('jobExecutionId', $scope.jobExecutionId);
      documentFormData.append('meetingId', $scope.gmId);
      documentFormData.append('issuerId', $scope.issuerId);
      documentFormData.append('issueName', $stateParams.issuerName);
      documentFormData.append('gmDate', $stateParams.gmDate);
      documentFormData.append('intermediaryUuid', $scope.selectedIntermediary.intermediaryUuid);

      if ($scope.bulkFile !== null && $scope.bulkFile !== undefined) {
        documentFormData.append('file', $scope.bulkFile);
        documentFormData.append('fileName', $scope.bulkFile.name);
      }

      /* upload file starts here */
      if ($scope.bulkFile === null || $scope.bulkFile === undefined) {
        $scope.error = true;
        $scope.errorMsg = $filter('translate')('uploadFile_error');
      } else {
        $scope.error = false;
        $scope.file = $scope.bulkFile;
        $scope.documentFormData = documentFormData;
        $scope.uploadUrl = 'v1/voteinstructions/upload';
        $scope.bulkUploadProcess($scope.file, $scope.documentFormData);
      }
    };

    $scope.startBulkFileUploadAr = function () {
      $scope.errorMsg = '';
      let documentFormData = new FormData();

      if ($scope.selectedLanguage && $scope.sendRcToShareholder === 'Y') {
        documentFormData.append('langCode', $scope.selectedLanguage.langCode);
      } else {
        documentFormData.append('langCode', $stateParams.langCode);
      }
      //Below line is added to send information that user has opted to use 'VEB' as default proxy for all records in upload file.
      documentFormData.append('defaultProxyProvided', $scope.defaultProxyForUpload);
      documentFormData.append('sendRcToShareholder', $scope.sendRcToShareholder);
      documentFormData.append('checkDuplicateAR', $scope.checkDuplicateAR);
      documentFormData.append('jobExecutionId', $scope.jobExecutionId);
      documentFormData.append('meetingId', $scope.gmId);
      documentFormData.append('issuerId', $scope.issuerId);
      documentFormData.append('issueName', $stateParams.issuerName);
      documentFormData.append('gmDate', $stateParams.gmDate);
      documentFormData.append('intermediaryUuid', $scope.selectedIntermediary.intermediaryUuid);

      if ($scope.bulkFile !== null && $scope.bulkFile !== undefined) {
        documentFormData.append('file', $scope.bulkFile);
        documentFormData.append('fileName', $scope.bulkFile.name);
      }

      /* upload file starts here */
      if ($scope.bulkFile === null || $scope.bulkFile === undefined) {
        $scope.error = true;
        $scope.errorMsg = $filter('translate')('uploadFile_error');
      } else {
        $scope.error = false;
        $scope.file = $scope.bulkFile;
        $scope.documentFormData = documentFormData;
        $scope.uploadUrl = 'v1/generalmeetings/attendances/fileupload';
        $scope.bulkUploadProcess($scope.file, $scope.documentFormData);
      }
    };

    $scope.bulkFileUploadFunctionBO = function () {
      var evnt;
      if ($scope.bulkFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        $scope.errorHere = true;
        $scope.errorMsg = $filter('translate')('label_file_format_xlsx');
      } else {
        $scope.errorHere = false;
        var documentFormData = new FormData();

        if ($scope.type === 'vote') {
          evnt = 'VI';
        } else if ($scope.type === 'attend') {
          evnt = 'AR';
          documentFormData.append('language', $scope.selectedLanguage.langCode);
          documentFormData.append('sendRcToShareholder', $scope.sendRcToShareholder);
        }
        documentFormData.append('boSource', evnt);
        if ($scope.bulkFile) {
          documentFormData.append('file', $scope.bulkFile);
          documentFormData.append('fileName', $scope.bulkFile.name);
        }
        documentFormData.append('langCode', $stateParams.langCode);
        documentFormData.append('meetingId', $scope.gmId);
        documentFormData.append('registrationNo', $scope.regNo);

        /* upload file starts here */
        if ($scope.bulkFile === null || $scope.bulkFile === undefined) {
          $scope.error = true;
          $scope.errorMsg = $filter('translate')('uploadFile_error');
        } else {
          $scope.error = false;
          $scope.uploadUrl = 'v1/beneficiaries/upload';
          /* Call the upload directive to upload file and FormData */
          fileUploadSvc.uploadFileToUrl($scope.bulkFile, $scope.uploadUrl, documentFormData)
            .then(
              function (data) {
                if (data.status === 200) {
                  $scope.success = true;
                  $scope.message = data.data;
                  $log.debug(' $scope.message', $scope.message.message);
                  $timeout(function () {
                    $scope.success = false;
                  }, 3000);
                } else {
                  $log.debug('error');
                  $scope.errorHere = true;
                  $scope.errorMsg = data.data.errors[0].errorMessage;
                  $timeout(function () {
                    $scope.errorHere = false;
                  }, 3000);
                }
              },
              function (error) {
                $log.debug(error);
                $scope.errorHere = true;
                $scope.errorMsg = error.message;
                $timeout(function () {
                  $scope.errorHere = false;
                }, 3000);
              }
            );
        }
      }
    };
    if (
      $scope.issuerName === 'Telenet Group Holding NV' ||
      ($scope.issuerName === 'Greenyard N.V.' && $scope.type === 'vote')
    ) {
      $scope.ShowTelenetPolicy = true;
      voteInstructionService
        .getIssuerPolicyText($scope.gmId, 'EN', $sessionStorage.usertype)
        .then(
          function (response) {
            if (response.status == 204) {
              $scope.istelenetPolicyTextPresent = false;
            } else {
              $scope.istelenetPolicyTextPresent = true;
              $scope.telenetPolicyText = response.data.policyText;
            }
          },
          function (error) {
            $log.debug(error);
            return error;
          }
        );
    }

    $scope.formattedLocationString = () => {
      return formatLocationString([
        $scope.selectedSecurityClass?.locationOnTicket,
        $scope.selectedSecurityClass?.street,
        $scope.selectedSecurityClass?.city,
        $scope.selectedSecurityClass?.postalCode,
        $scope.selectedSecurityClass?.country,
      ]);
    };
  },
]);
